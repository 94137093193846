
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TimelineMax, TweenMax } from 'gsap';
import {
  BsModalRef,
  ModalDirective,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { environment as env } from '../../../environments/environment';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import jsQR from 'jsqr';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/services/bugsnag.service';


declare var $: any;

declare let Winwheel: any;

@Component({
  selector: 'app-similac-spin-wheel',
  templateUrl: './similac-spin-wheel.component.html',
  styleUrls: ['./similac-spin-wheel.component.scss'],
})
export class SimilacSpinWheelComponent implements OnInit {
  theWheel: any;
  wheelPower = 0;
  wheelSpinning = false;
  winningSegment: string;
  myDate = new Date();
  termsContent: any;
  remaining_similac_chances: any = 0;

  scanCode: any;
  categoryCode: any;
  checkQRCode2: any;
  scancodeData: any;
  prodCategory: any;
  growtermsContent: any;
  scannedDate: any;
  gift_delivery_date: any;
  is_eligilbe_grow: boolean;
  is_grow: boolean = false;
  showProfile: Boolean = true;
  custom_isGrow: any = false;
  eligible_nonmilk_grow: boolean = false;
  eligible_nonmilk_similac: boolean = false;
  non_redemmed_grow: any = [];
  non_redemmed_similac: any = [];
  redemmed: boolean = false;
  is_not_expired: boolean = false;
  game_is_eligible: boolean = false;
  all_nonmilk_array: any = [];
  nonmilk_grow_array: any = [];
  nonmilk_similac_array: any = [];
  cp_non_similac: any = [];
  authToken: any;
  modalRef: BsModalRef;
  videoStart = false;
  medias: MediaStreamConstraints = {
    audio: false,
    video: false,
  };
  showScanner: boolean = false;
  expiryDate: any;
  remaining_grow_chances: any;
  FinalMilkcode: any;

  similac_array_length: any;
  grow_array_length: any;
  activeTab: any;
  showActiveSimilacTab = false;
  custom_isEnsure: boolean;
  custom_isGlucerna: boolean;
  spinDisable: boolean = false;
  global_non_redemmed_similac: any = [];

  @ViewChild('rewardModal', { static: true }) rewardModal: ModalDirective;

  @ViewChild(ModalDirective, { static: false }) modal: ModalDirective;
  @ViewChild('video', { static: true }) videoElm: ElementRef;
  @ViewChild('canvas', { static: true }) canvasElm: ElementRef;
  @ViewChild('invalidQrCodeModal', { static: true })
  invalidQrCodeModal: ModalDirective;
  @ViewChild('milkCodeModal', { static: true }) milkCodeModal: ModalDirective;
  @ViewChild('successQrCodeModal', { static: true })
  successQrCodeModal: ModalDirective;
  @ViewChild('betterLuckNextTime', { static: true })
  betterLuckNextTime: ModalDirective;
  @ViewChild('termsSimilac', { static: true }) termsSimilac: ModalDirective;
  config = {
    ignoreBackdropClick: true
  };

  gift_segments = [
      // { id: 1, text: 'Prize 1', value: 'P-009324' },
      // { id: 2, text: 'Prize 2', value: 'P-00000' },
      // { id: 3, text: 'Prize 3', value: 'P-010327' },
      // { id: 4, text: 'Prize 4', value: 'P-00000' },
      // { id: 5, text: 'Prize 5', value: 'P-008861' },
      // { id: 6, text: 'Prize 6', value: 'P-00000' },
      // { id: 7, text: 'Prize 7', value: 'P-010326' },
      // { id: 8, text: 'Prize 8', value: 'P-00000' },

      { id: 1, text: 'Prize 1', value: 'P-013528' },
      { id: 2, text: 'Prize 2', value: 'P-00000' },
      { id: 3, text: 'Prize 3', value: 'P-009375' },
      { id: 4, text: 'Prize 4', value: 'P-00000' },
      { id: 5, text: 'Prize 5', value: 'P-010327' },
      { id: 6, text: 'Prize 6', value: 'P-00000' },
      { id: 7, text: 'Prize 7', value: 'P-010326' },
      { id: 8, text: 'Prize 8', value: 'P-00000' },

    // { id: 1, text: 'Prize 1', value: 'P-008431' },
    // { id: 2, text: 'Prize 2', value: 'P-00000' },
    // { id: 3, text: 'Prize 3', value: 'P-008366' },
    // { id: 4, text: 'Prize 4', value: 'P-00000' },
    // { id: 5, text: 'Prize 5', value: 'P-008431' },
    // { id: 6, text: 'Prize 6', value: 'P-00000' },
    // { id: 7, text: 'Prize 7', value: 'P-008366' },
    // { id: 8, text: 'Prize 8', value: 'P-00000' },

  ];
  timestamp: number;
  iSprintData: any;
  similacChances: string;
  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private bsService: BugsnagService
  ) {
    this.timestamp = Date.now();
    console.log(this.timestamp, 'date now')
    this.scanCode = sessionStorage.getItem('scanCode');
    this.myDate.setDate(this.myDate.getDate() + 45);
    this.authToken = sessionStorage.getItem('authToken');
    this.activeTab = this.route.snapshot.params.id;
    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"));
    console.log(this.iSprintData);
    if (this.scanCode != 'undefined') {
      // let qrCodeData = JSON.parse(sessionStorage.getItem('productdata'));
      // this.categoryCode = qrCodeData?.product?.categoryCode;
      // this.checkQRCode2 = qrCodeData?.uaidInfo?.type;
      // this.scancodeData = qrCodeData;
      // this.prodCategory = this.categoryCode;
      this.checkQRCode2 = this.iSprintData?.type;
      this.categoryCode = this.iSprintData?.category_code;
      this.prodCategory = this.iSprintData?.category_code;

      // let scannedCode = qrCodeData;
      // this.productCode = scannedCode.product.productCode;
    } else {
      console.log('cleared local storage---');
    }
    let today = new Date();
    this.scannedDate = moment(today).format('YYYY-MM-DD');

    let endDate = moment(today).add(45, 'days');
    this.gift_delivery_date = moment(endDate).format('YYYY-MM-DD');
    let expiry_date = moment(today).add(30, 'days');
    this.expiryDate = moment(expiry_date).format('YYYY-MM-DD');

    // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    // this.prodCategory = this.scancodeData?.product?.categoryCode;

    this.categoryCode == 'Similac'
      ? (this.is_grow = false)
      : (this.is_grow = true);

    setTimeout(() => {
      this.remaining_grow_chances = JSON.parse(
        sessionStorage.getItem('grow_nonmilk_remaining_count')
      );
      this.remaining_similac_chances = JSON.parse(
        sessionStorage.getItem('similac_nonmilk_remaining_count')
      );

      this.similac_array_length = JSON.parse(
        sessionStorage.getItem('similacMilCount')
      );
      this.grow_array_length = JSON.parse(
        sessionStorage.getItem('growMilkCount')
      );

      if (this.similac_array_length == 0 || this.similac_array_length == null) {
        this.remaining_similac_chances = 5;
      }
      if (this.grow_array_length == 0 || this.similac_array_length == null) {
        this.remaining_grow_chances = 5;
      }

      this.remaining_grow_chances =
        this.remaining_grow_chances < 0
          ? (this.remaining_grow_chances = 0)
          : 0 + this.remaining_grow_chances;
      this.remaining_similac_chances =
        this.remaining_similac_chances < 0
          ? (this.remaining_similac_chances = 0)
          : 0 + this.remaining_similac_chances;
      // if(!this.authToken){
      //   this.remaining_grow_chances = 5;
      //   this.remaining_similac_chances = 5;

      // }
    }, 2000);
  }

  ngOnInit(): void {
    this.getGameId();
    this.similacTerms();
    this.similacChances = sessionStorage.getItem('similac_chances')
    console.log('---', this.activeTab);
    if (this.activeTab === '21') {
      console.log('helloid');
      this.showActiveSimilacTab = true;
    } else {
      this.showActiveSimilacTab = false;
    }
  }

  ngAfterViewInit() {
    this.theWheel = new Winwheel({
      canvasId: 'canvasOne',
      responsive: true,
      numSegments: 8, // Specify number of segments.
      outerRadius: 200, // Set outer radius so wheel fits inside the background.
      // drawText          : true,             // Code drawn text can be used with segment images.
      textFontSize: 12,
      textOrientation: 'curved', // Note use of curved text.
      textDirection: 'reversed', // Set other text options as desired.
      textAlignment: 'outer',
      textMargin: 5,
      textFontFamily: 'monospace',
      textStrokeStyle: 'black',
      textLineWidth: 3,
      textFillStyle: 'white',
      drawMode: 'image', // Must be segmentImage to draw wheel using one image per segemnt.
      segments: this.gift_segments, // Define segments.
      // Specify the animation to use.
      animation: {
        type: 'spinToStop',
        duration: 8,
        spins: 15,
        //  easing       : 'Power2.easeInOut',
        callbackFinished: this.alertPrize.bind(this),
      },
    });

    this.loadWheel(this.theWheel);
    setTimeout(() => {
      this.resetWheel();
    }, 1000);
    //  this.setCanvas();
  }
  loadWheel(wheel) {
    let loadedImg = new Image();

    // Create callback to execute once the image has finished loading.
    loadedImg.onload = function () {
      wheel.wheelImage = loadedImg; // Make wheelImage equal the loaded image object.
      wheel.draw(); // Also call draw function to render the wheel.
    };

    //loadedImg.src = '../../../assets/images/similac-new_gift.png';
    // loadedImg.src = '../../../assets/images/similac-gift.png';
    //  loadedImg.src = '../../../assets/images/similac-gift-june.png';
    //loadedImg.src = '../../../assets/images/nov-games/similac-spin-wheel.png';
    //loadedImg.src = '../../../assets/images/april-games/similac-spin-wheel.png'
    // loadedImg.src = '../../../assets/images/Jan24-games/similacspinwheeljan24.png'
    loadedImg.src = '../../../assets/images/july2024/similac.png';

    loadedImg.width = 270;
    loadedImg.height = 270;
  }

  startSpin(): void {
    if (this.wheelSpinning === false) {
      if (this.wheelPower === 1) {
        this.theWheel.animation.spins = 3;
      } else if (this.wheelPower === 2) {
        this.theWheel.animation.spins = 8;
      } else if (this.wheelPower === 3) {
        this.theWheel.animation.spins = 15;
      }
      //  }
      this.theWheel.startAnimation(new TweenMax(new TimelineMax()));
      this.wheelSpinning = true;
    }
  }

  resetWheel(): void {
    this.theWheel.stopAnimation(false);
    this.theWheel.rotationAngle = 0;
    this.theWheel.draw();
    this.wheelSpinning = false;
  }

  alertPrize(): void {
    if (this.user_gift == 'P-00000') {
      this.openbetterLuckModal();
    } else {
      this.openRewardPopup();
    }
  }

  getSegment(e): void {
    const clickedSegment = this.theWheel.getSegmentAt(e.clientX, e.clientY);
  }

  calculatePrize() {
    this.spinDisable = true;
    if (this.gameId != null && this.gameId != undefined) {
      this.initialUpdateTransactions();
    }else{
      this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
    }
  }

  gameId: any;
  growNonMilk: any;
  SimilacMom: any;
  SimilacS4: any;
  getGameId() {
    this.apiService.sttarterLuckyDrawGameId().subscribe((res: any) => {
      this.spinner.hide();
      if (res.status == 200) {
        let brandId = res.data.filter(
          (brandid) => brandid.gamebrand == 'BrandId'
        );
        this.gameId = brandId[0].gameid;

        let grownonmilk = res.data.filter(
          (grownonmilk) => grownonmilk.gamebrand == 'Grow Non-Milk'
        );
        this.growNonMilk = grownonmilk[0].gameid;
        console.log(this.growNonMilk, 'this.growNonMilk');

        let SimilacMom = res.data.filter(
          (SimilacMom) => SimilacMom.gamebrand == 'Similac Mom'
        );
        this.SimilacMom = SimilacMom[0].gameid;
        console.log(this.SimilacMom, 'this.SimilacMom');

        let SimilacS4 = res.data.filter(
          (SimilacS4) => SimilacS4.gamebrand == 'Similac S4'
        );
        this.SimilacS4 = SimilacS4[0].gameid;
        console.log(this.SimilacS4, 'this.SimilacS4');
        this.getPreviousTranscation();

      } else {
        this.spinner.hide();
        this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
      }
    }, err => {
      this.spinner.hide();
      this.bsService.notifyError(err);
    });
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  content: any = [];
  subCatCode: any;
  milkCode: any;
  is_milk: boolean = false;
  sttrData: any;
  // getProductDescCode() {
  //   this.content = this.scancodeData?.product?.txt;
  //   if (this.content) {
  //     this.content.forEach((item: any) => {
  //       if (this.parseFunction(item.name).en_US == 'SubCategoryCode') {
  //         let parsedPrimaryValue = this.parseFunction(item.value);
  //         this.subCatCode = parsedPrimaryValue.value;
  //       }

  //       if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
  //         let parsedPrimaryValue = this.parseFunction(item.value);
  //         this.milkCode = parsedPrimaryValue.value;
  //         this.milkCode == 0 ? (this.is_milk = false) : (this.is_milk = true);
  //       }
  //     });
  //   }
  // }

  user_gift: any;
  momItemCode: any;
  //  gameAllocation(){
  //             let element = this.global_non_redemmed_similac[0];
  //             if(element[10]?.name == 'itemcode'){
  //               this.momItemCode = element[10]?.value;
  //             }else if(element[11]?.name == 'itemcode'){
  //               this.momItemCode = element[11]?.value;
  //             }else if(element[9]?.name == 'itemcode'){
  //               this.momItemCode = element[19]?.value;
  //             }

  //             console.log(this.momItemCode ,'this.momItemCode code');
  //             if(this.momItemCode == '2010' || this.momItemCode == '2011' ){
  //               let mobileNo = sessionStorage.getItem('mobileNo');
  //               let authToken = sessionStorage.getItem('authToken');
  //               let formdata = {
  //                 "brandId" :this.gameId,
  //                 "gameId" : this.SimilacMom,
  //                 "mobile" : parseInt(mobileNo)
  //               }
  //               console.log(formdata, 'formdata SimilacMom')
  //               this.spinner.show();
  //               let myuuid = uuidv4();
  //                     let loggerFormData = {
  //                       "unique_id": myuuid,
  //                       "message": JSON.stringify(formdata)
  //                     }
  //               this.apiService.gameAllocate(formdata, mobileNo, authToken).subscribe(res=>{
  //                 let myuuid = uuidv4();
  //                     let loggerFormData = {
  //                       "unique_id": myuuid,
  //                       "message": JSON.stringify(res)
  //                     }
  //                 this.spinner.hide();
  //                 if(res['status'].code == 200){
  //                   this.spinner.show();
  //                   this.apiService.gamePlay(mobileNo,authToken,this.SimilacMom).subscribe(res=>{
  //                     this.spinner.hide();
  //                     if(res['status'].code == 5200){
  //                       let rewardVal = res['reward'].value
  //                       this.user_gift = rewardVal
  //                       this.spinner.show();
  //                       this.apiService.sttarterGetGameplayReward(rewardVal).subscribe(res=>{
  //                         this.spinner.hide();
  //                         this.sttrData = res['data'][0];

  //                           let giftId = this.gift_segments.filter(val =>{
  //                             if(val.value == rewardVal){
  //                               return val.id;
  //                             }
  //                             else{
  //                               console.log('value not found')
  //                             }
  //                           })
  //                           let stopAt = this.theWheel.getRandomForSegment(giftId[0].id);

  //                          this.theWheel.animation.stopAngle = stopAt;
  //                           if(this.global_non_redemmed_similac.length > 0){
  //                               this.startSpin();
  //                           }

  //                       })
  //                   }  //-----------game play success------------
  //                    else{}
  //                   },err=>{
  //                     this.spinner.hide();
  //                     //this.apiService.showToaster(err);
  //                     console.log(err, 'login error')
  //                   })
  //                 }
  //                 else{
  //                   //this.apiService.showToaster(res['status'].message);
  //                   console.log(res['status'].message);
  //                 }
  //               },err => {
  //                 this.spinner.hide();
  //                 //this.apiService.showToaster(err);
  //                 console.log(err, 'login error')
  //               });
  //             }
  //             //----------------other similac product-----------
  //             else{
  //                console.log('Other Similac products')
  //             let mobileNo = sessionStorage.getItem('mobileNo');
  //             let authToken = sessionStorage.getItem('authToken');
  //             let formdata ={
  //               "brandId" :this.gameId,
  //               "gameId" : this.SimilacS4,
  //               "mobile" : parseInt(mobileNo)
  //             }
  //             console.log(formdata, 'formdata SimilacS4')
  //             this.spinner.show();
  //             let myuuid = uuidv4();
  //                     let loggerFormData = {
  //                       "unique_id": myuuid,
  //                       "message": JSON.stringify(formdata)
  //                     }
  //             this.apiService.gameAllocate(formdata, mobileNo, authToken).subscribe(res=>{
  //               let myuuid = uuidv4();
  //                     let loggerFormData = {
  //                       "unique_id": myuuid,
  //                       "message": JSON.stringify(res)
  //                     }
  //               this.spinner.hide();
  //               if(res['status'].code == 200){
  //                 this.apiService.gamePlay(mobileNo,authToken,this.SimilacS4).subscribe(res=>{
  //                   this.spinner.hide();
  //                   if(res['status'].code == 5200){
  //                     let rewardVal = res['reward'].value
  //                     this.user_gift = rewardVal
  //                     this.spinner.show();
  //                     this.apiService.sttarterGetGameplayReward(rewardVal).subscribe(res=>{
  //                       this.spinner.hide();
  //                       this.sttrData = res['data'][0];

  //                           let giftId = this.gift_segments.filter(val =>{
  //                             if(val.value == rewardVal){
  //                               return val.id;
  //                             }
  //                             else{
  //                               console.log('value not found')
  //                             }
  //                           })
  //                           let stopAt = this.theWheel.getRandomForSegment(giftId[0].id);

  //                          this.theWheel.animation.stopAngle = stopAt;
  //                           if(this.global_non_redemmed_similac.length > 0){
  //                               this.startSpin();
  //                           }

  //                     })
  //                   }
  //                  else{}

  //                 },err=>{
  //                   this.spinner.hide();
  //                   this.apiService.showToaster(err);
  //                   console.log(err, 'login error')
  //                 })
  //               }
  //               else{
  //                 this.spinner.hide();
  //               }
  //             },err => {
  //               this.spinner.hide();
  //               this.apiService.showToaster(err);
  //               console.log(err, 'login error')
  //             });

  //             }

  // }


  gameAllocation(transactionNumber, itemcode) {
    let mobileNo = sessionStorage.getItem('mobileNo');
    let authToken = sessionStorage.getItem('authToken');
    var formdata = {
      brandId: this.gameId,
      gameId: this.SimilacS4,
      mobile: parseInt(mobileNo),
      transactionNumber: transactionNumber,
      brandName: "Similac S4",
      countryCode: env.COUNTRY_CODE
    };
    if (itemcode == '2010' || itemcode == '2011') {
      formdata = {
        brandId: this.gameId,
        gameId: this.SimilacMom,
        mobile: parseInt(mobileNo),
        transactionNumber: transactionNumber,
        brandName: "Similac Mom",
        countryCode: env.COUNTRY_CODE
      };
    }
    this.spinner.show();
    this.apiService.newGameAllocation(formdata, mobileNo, authToken).subscribe((res: any) => {
      this.spinner.hide();
      if (res?.result?.code == 200) {
        this.spinDisable = false;
        console.log(res, 'game api responce')
        let actual_gift_code = res?.result?.actual_gift_code;
        this.user_gift = actual_gift_code?.gift_id;

        let giftId = this.gift_segments.filter((val) => {
          if (val.value == this.user_gift) {
            console.log(val.id)
            return val.id;
          } else {
            console.log('no value matched--');
          }
        });
        let stopAt = this.theWheel.getRandomForSegment(giftId[0].id);
        this.theWheel.animation.stopAngle = stopAt;
        //this.updateTransactions(transcationNumber);
        this.startSpin();
        this.sttrData = res?.result?.actual_gift_code;
      }
    }, err => {
      this.spinDisable = false;
      this.spinner.hide();
      if (err?.error?.code == 500) {
        if (
           err?.error?.errorcode == 400 ||
           err?.error?.errorcode == 600 ||
           err?.error?.errorcode == 700 ||
           err?.error?.errorcode == 701 
         ) {
           this.spinner.hide();
           this.apiService.showToaster(
             'Đã xảy ra lỗi. Vui lòng thử lại sau'
           );
         } else if (
           err?.error?.errorcode == 1001 
         ) {
           this.spinner.hide();
           this.apiService.showToaster(
             'Bạn đã đạt đến giới hạn trò chơi trong tháng, vui lòng thử lại vào tháng sau.'
           );
         } else if (
           err?.error?.errorcode == 401 ||
           err?.error?.errorcode == 801 
         ) {
           this.spinner.hide();
           this.apiService.showToaster(
             'Vui lòng đăng nhập lại và thử lại.'
           );
         } else if (
           err?.error?.errorcode == 601 
         ) {
           this.spinner.hide();
           this.apiService.showToaster(
             'Đã xảy ra lỗi trong lúc quay, vui lòng thử lại sau.'
           );
         } else if (
           err?.error?.errorcode == 802 ||
           err?.error?.errorcode == 803 ||
           err?.error?.errorcode == 804 
         ) {
           this.spinner.hide();
           this.apiService.showToaster(
             'Bạn không đủ điều kiện để tham gia. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
           );
         } else if (
           err?.error?.errorcode == 900 
         ) {
           this.spinner.hide();
           this.apiService.showToaster(
             'Vòng quay chưa sẵn sàng. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
           );
         } else if (
           err?.error?.errorcode == 1000 || err?.error?.errorcode == 5503 
         ) {
           this.spinner.hide();
           this.apiService.showToaster(
             'Trò chơi đã kết thúc trong tháng này. Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm chi tiết.'
           );
         } else if (
           err?.error?.errorcode == 5402 
         ) {
           this.spinner.hide();
           this.apiService.showToaster(
             'Vật phẩm trong trò chơi đã hết, trò chơi kết thúc.'
           );
         } else if (
           err?.error?.errorcode == 5500 
         ) {
           this.spinner.hide();
           this.apiService.showToaster(
             'Ngoại lệ trong khi đáp ứng yêu cầu Chơi trò chơi'
           );
         }else{
          this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
         }
     }else{
      this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
     }
      console.log(err, 'error')
      this.bsService.notifyError(err);
    })
  }

  // gameAllocation(transcationNumber, itemcode) {
  //   // let element = this.global_non_redemmed_similac[0];
  //   // if (element[10]?.name == 'itemcode') {
  //   //   this.momItemCode = element[10]?.value;
  //   // } else if (element[11]?.name == 'itemcode') {
  //   //   this.momItemCode = element[11]?.value;
  //   // } else if (element[9]?.name == 'itemcode') {
  //   //   this.momItemCode = element[19]?.value;
  //   // } else if (element[12]?.name == 'itemcode') {
  //   //   this.momItemCode = element[19]?.value;
  //   // }

  //   // console.log(this.momItemCode, 'this.momItemCode code');

  //   if (itemcode == '2010' || itemcode == '2011') {
  //     let mobileNo = sessionStorage.getItem('mobileNo');
  //     let authToken = sessionStorage.getItem('authToken');
  //     let formdata = {
  //       brandId: this.gameId,
  //       gameId: this.SimilacMom,
  //       mobile: parseInt(mobileNo),
  //     };
  //     console.log(formdata, 'formdata SimilacMom');
  //     this.spinner.show();
  //     let myuuid = uuidv4();
  //     let loggerFormData = {
  //       unique_id: myuuid,
  //       message: JSON.stringify(formdata),
  //     };
  //     let gameallocateresp
  //     this.apiService.gameAllocate(formdata, mobileNo, authToken).subscribe(
  //       (res) => {
  //         gameallocateresp = res;
  //         let myuuid = uuidv4();
  //         let loggerFormData = {
  //           unique_id: myuuid,
  //           message: JSON.stringify(res),
  //         };
  //         this.spinner.hide();
  //         // if (res['status'].code == 200 || res['status'].message == 'Game already allocated maximum number of times') {
  //         if (res['status'].code == 200) {
  //           this.spinner.show();
  //           let gameplayresp = res;
  //           this.apiService
  //             .gamePlay(mobileNo, authToken, this.SimilacMom)
  //             .subscribe(
  //               (res) => {
  //                 gameplayresp = res;
  //                 this.spinner.hide();
  //                 if (res['status'].code == 5200) {
  //                   let rewardVal = res['reward'].value;
  //                   // this.user_gift = rewardVal

  //                   this.spinner.show();
  //                   let getcouponsresp
  //                   this.apiService
  //                     .getCoupons(rewardVal, mobileNo, authToken)
  //                     .subscribe(
  //                       (res) => {
  //                         getcouponsresp = res;
  //                         let myuuid = uuidv4();
  //                         let loggerFormData = {
  //                           "unique_id": myuuid,
  //                           "message": JSON.stringify(res)
  //                         }
  //                         this.spinner.hide();
  //                         let series_id =
  //                           res['response']['coupons']['coupon'][0].series_id;
  //                         console.log(series_id, 'series_id--');

  //                         this.spinner.show();
  //                         let getseriescoderesp;
  //                         this.apiService
  //                           .getCouponsSeriesCode(
  //                             series_id,
  //                             mobileNo,
  //                             authToken
  //                           )
  //                           .subscribe(
  //                             (res) => {
  //                               getseriescoderesp = res;
  //                               let myuuid = uuidv4();
  //                               let loggerFormData = {
  //                                 "unique_id": myuuid,
  //                                 "message": JSON.stringify(res)
  //                               }
  //                               this.spinner.hide();
  //                               this.spinDisable = false;
  //                               let actual_gift_code =
  //                                 res['response']['series']['items']['item'][0]
  //                                   .discount_code;
  //                               this.user_gift = actual_gift_code;
  //                               console.log(
  //                                 actual_gift_code,
  //                                 'actual_gift_code--'
  //                               );
  //                               let sttraterresp;
  //                               this.spinner.show();
  //                               this.apiService
  //                                 .sttarterGetGameplayReward(actual_gift_code)
  //                                 .subscribe((res) => {
  //                                   sttraterresp = res;
  //                                   let myuuid = uuidv4();
  //                                   let loggerFormData = {
  //                                     "unique_id": myuuid,
  //                                     "message": JSON.stringify(res)
  //                                   }
  //                                   this.spinner.hide();
  //                                   this.sttrData = res['data'][0];

  //                                   let giftId = this.gift_segments.filter(
  //                                     (val) => {
  //                                       if (val.value == actual_gift_code) {
  //                                         return val.id;
  //                                       } else {
  //                                         console.log('value not found');
  //                                       }
  //                                     }
  //                                   );
  //                                   let stopAt =
  //                                     this.theWheel.getRandomForSegment(
  //                                       giftId[0].id
  //                                     );

  //                                   this.theWheel.animation.stopAngle = stopAt;
  //                                   // if (
  //                                   //   this.global_non_redemmed_similac.length >
  //                                   //   0
  //                                   // ) {
  //                                     this.updateTransactions(transcationNumber);
  //                                     this.startSpin();
  //                                   //}
  //                                 }, err => {
  //                                   this.spinner.hide();
  //                                   this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
  //                                 });
  //                             },
  //                             (err) => {
  //                               this.spinner.hide();
  //                               this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
  //                               console.log(err, 'coupon series err--');
  //                             }
  //                           );
  //                       },
  //                       (err) => {
  //                         this.spinner.hide();
  //                         this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
  //                         console.log(err, 'coupon get err--');
  //                       }
  //                     );
  //                 } //-----------game play success------------
  //                 else if (
  //                   res['status'].code == 1001 ||
  //                   res['status'].code == 400 ||
  //                   res['status'].code == 600 ||
  //                   res['status'].code == 700 ||
  //                   res['status'].code == 701 ||
  //                   res['status'].message ==
  //                   'Something Went Wrong. Please try again later.'
  //                 ) {
  //                   this.spinner.hide();
  //                   this.apiService.showToaster(
  //                     'Đã xảy ra lỗi. Vui lòng thử lại sau'
  //                   );
  //                 } else if (
  //                   res['status'].code == 1001 ||
  //                   res['status'].message ==
  //                   'You have reached the gameplay limit for the month, please try again next month.'
  //                 ) {
  //                   this.spinner.hide();
  //                   this.apiService.showToaster(
  //                     'Bạn đã đạt đến giới hạn trò chơi trong tháng, vui lòng thử lại vào tháng sau.'
  //                   );
  //                 } else if (
  //                   res['status'].code == 401 ||
  //                   res['status'].code == 801 ||
  //                   res['status'].message == 'Please Re-login and try again.'
  //                 ) {
  //                   this.spinner.hide();
  //                   this.apiService.showToaster(
  //                     'Vui lòng đăng nhập lại và thử lại.'
  //                   );
  //                 } else if (
  //                   res['status'].code == 601 ||
  //                   res['status'].message ==
  //                   'There was some problem while playing the game. Please try after some time.'
  //                 ) {
  //                   this.spinner.hide();
  //                   this.apiService.showToaster(
  //                     'Đã xảy ra lỗi trong lúc quay, vui lòng thử lại sau.'
  //                   );
  //                 } else if (
  //                   res['status'].code == 802 ||
  //                   res['status'].code == 803 ||
  //                   res['status'].code == 804 ||
  //                   res['status'].message ==
  //                   'You are not eligible for the game. Please contact support for more details.'
  //                 ) {
  //                   this.spinner.hide();
  //                   this.apiService.showToaster(
  //                     'Bạn không đủ điều kiện để tham gia. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
  //                   );
  //                 } else if (
  //                   res['status'].code == 900 ||
  //                   res['status'].message ==
  //                   'Game has not been started yet. Please contact support for more details.'
  //                 ) {
  //                   this.spinner.hide();
  //                   this.apiService.showToaster(
  //                     'Vòng quay chưa sẵn sàng. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
  //                   );
  //                 } else if (
  //                   res['status'].code == 1000 || res['status'].code == 5503 ||
  //                   res['status'].message ==
  //                   'Game is over for this month. Please contact support for more details.'
  //                 ) {
  //                   this.spinner.hide();
  //                   this.apiService.showToaster('Trò chơi đã kết thúc trong tháng này. Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm chi tiết.')
  //                 } else if (res['status'].code == 5402 || res['status'].message == 'Game Items exhausted, Game over.') {
  //                   this.spinner.hide();
  //                   this.apiService.showToaster('Vật phẩm trong trò chơi đã hết, trò chơi kết thúc.')
  //                 } else if (res['status'].code == 5500 || res['status'].message == 'Exception while fulfilling Game Play request') {
  //                   this.spinner.hide();
  //                   this.apiService.showToaster('Ngoại lệ trong khi đáp ứng yêu cầu Chơi trò chơi')
  //                 }
  //               },
  //               (err) => {
  //                 this.spinner.hide();
  //                 this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
  //                 console.log(err, 'coupon get err')
  //                 //this.apiService.showToaster(err);
  //                 console.log(err, 'login error');
  //               }
  //             );
  //         } else if (
  //           res['status'].code == 1001 ||
  //           res['status'].code == 400 ||
  //           res['status'].code == 600 ||
  //           res['status'].code == 700 ||
  //           res['status'].code == 701 ||
  //           res['status'].message ==
  //           'Something Went Wrong. Please try again later.'
  //         ) {
  //           this.spinner.hide();
  //           this.apiService.showToaster('Đã xảy ra lỗi. Vui lòng thử lại sau');
  //         } else if (
  //           res['status'].code == 1001 ||
  //           res['status'].message ==
  //           'You have reached the gameplay limit for the month, please try again next month.'
  //         ) {
  //           this.spinner.hide();
  //           this.apiService.showToaster(
  //             'Bạn đã đạt đến giới hạn trò chơi trong tháng, vui lòng thử lại vào tháng sau.'
  //           );
  //         } else if (
  //           res['status'].code == 401 ||
  //           res['status'].code == 801 ||
  //           res['status'].message == 'Please Re-login and try again.'
  //         ) {
  //           this.spinner.hide();
  //           this.apiService.showToaster('Vui lòng đăng nhập lại và thử lại.');
  //         } else if (
  //           res['status'].code == 601 ||
  //           res['status'].message ==
  //           'There was some problem while playing the game. Please try after some time.'
  //         ) {
  //           this.spinner.hide();
  //           this.apiService.showToaster(
  //             'Đã xảy ra lỗi trong lúc quay, vui lòng thử lại sau.'
  //           );
  //         } else if (
  //           res['status'].code == 802 ||
  //           res['status'].code == 803 ||
  //           res['status'].code == 804 ||
  //           res['status'].message ==
  //           'You are not eligible for the game. Please contact support for more details.'
  //         ) {
  //           this.spinner.hide();
  //           this.apiService.showToaster(
  //             'Bạn không đủ điều kiện để tham gia. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
  //           );
  //         } else if (
  //           res['status'].code == 900 ||
  //           res['status'].message ==
  //           'Game has not been started yet. Please contact support for more details.'
  //         ) {
  //           this.spinner.hide();
  //           this.apiService.showToaster(
  //             'Vòng quay chưa sẵn sàng. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
  //           );
  //         } else if (
  //           res['status'].code == 1000 ||
  //           res['status'].message ==
  //           'Game is over for this month. Please contact support for more details.'
  //         ) {
  //           this.spinner.hide();
  //           this.apiService.showToaster('Trò chơi đã kết thúc trong tháng này. Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm chi tiết.')
  //         } else if (res['status'].code == 5402 || res['status'].message == 'Game Items exhausted, Game over.') {
  //           this.spinner.hide();
  //           this.apiService.showToaster('Vật phẩm trong trò chơi đã hết, trò chơi kết thúc.')
  //         } else if (res['status'].code == 5500 || res['status'].message == 'Exception while fulfilling Game Play request') {
  //           this.spinner.hide();
  //           this.apiService.showToaster('Ngoại lệ trong khi đáp ứng yêu cầu Chơi trò chơi')
  //         }
  //       },
  //       (err) => {
  //         this.spinner.hide();
  //         this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
  //         // this.apiService.showToaster(err);
  //         console.log(err, 'login error');
  //       }
  //     );
  //   }
  //   //----------------other similac product-----------
  //   else {
  //     console.log('Other Similac products');
  //     let mobileNo = sessionStorage.getItem('mobileNo');
  //     let authToken = sessionStorage.getItem('authToken');
  //     let formdata = {
  //       brandId: this.gameId,
  //       gameId: this.SimilacS4,
  //       mobile: parseInt(mobileNo),
  //     };
  //     console.log(formdata, 'formdata SimilacS4');
  //     this.spinner.show();
  //     let myuuid = uuidv4();
  //     let loggerFormData = {
  //       unique_id: myuuid,
  //       message: JSON.stringify(formdata),
  //     };
  //     let gameallocateresp;
  //     this.apiService.gameAllocate(formdata, mobileNo, authToken).subscribe(
  //       (res) => {
  //         gameallocateresp = res;
  //         let myuuid = uuidv4();
  //         let loggerFormData = {
  //           unique_id: myuuid,
  //           message: JSON.stringify(res),
  //         };
  //         this.spinner.hide();

  //         if (res['status'].code == 200 || res['status'].message == 'Game already allocated maximum number of times') {
  //           let myuuid = uuidv4();
  //           let loggerFormData = {
  //             unique_id: myuuid,
  //             message: JSON.stringify(this.SimilacS4),
  //           };
  //           let gameplayresp
  //           this.apiService
  //             .gamePlay(mobileNo, authToken, this.SimilacS4)
  //             .subscribe(
  //               (res) => {
  //                 gameplayresp = res;
  //                 let myuuid = uuidv4();
  //                 let loggerFormData = {
  //                   unique_id: myuuid,
  //                   message: JSON.stringify(res),
  //                 };
  //                 this.spinner.hide();
  //                 if (res['status'].code == 5200) {
  //                   let rewardVal = res['reward'].value;
  //                   // this.user_gift = rewardVal

  //                   this.spinner.show();
  //                   let getcouponsresp
  //                   this.apiService
  //                     .getCoupons(rewardVal, mobileNo, authToken)
  //                     .subscribe(
  //                       (res) => {
  //                         getcouponsresp = res;
  //                         let myuuid = uuidv4();
  //                         let loggerFormData = {
  //                           "unique_id": myuuid,
  //                           "message": JSON.stringify(res)
  //                         }
  //                         this.spinner.hide();
  //                         let series_id =
  //                           res['response']['coupons']['coupon'][0].series_id;
  //                         console.log(series_id, 'series_id--');

  //                         this.spinner.show();
  //                         let getseriescoderesp;
  //                         this.apiService
  //                           .getCouponsSeriesCode(
  //                             series_id,
  //                             mobileNo,
  //                             authToken
  //                           )
  //                           .subscribe(
  //                             (res) => {
  //                               getseriescoderesp = res;
  //                               let myuuid = uuidv4();
  //                               let loggerFormData = {
  //                                 "unique_id": myuuid,
  //                                 "message": JSON.stringify(res)
  //                               }
  //                               this.spinner.hide();
  //                               let actual_gift_code =
  //                                 res['response']['series']['items']['item'][0]
  //                                   .discount_code;
  //                               this.user_gift = actual_gift_code;
  //                               console.log(
  //                                 actual_gift_code,
  //                                 'actual_gift_code--'
  //                               );

  //                               this.spinner.show();
  //                               let sttraterresp;
  //                               this.apiService
  //                                 .sttarterGetGameplayReward(actual_gift_code)
  //                                 .subscribe((res) => {
  //                                   sttraterresp = res;

  //                                   this.spinner.hide();
  //                                   this.sttrData = res['data'][0];

  //                                   let giftId = this.gift_segments.filter(
  //                                     (val) => {
  //                                       if (val.value == actual_gift_code) {
  //                                         return val.id;
  //                                       } else {
  //                                         console.log('value not found');
  //                                       }
  //                                     }
  //                                   );
  //                                   let stopAt =
  //                                     this.theWheel.getRandomForSegment(
  //                                       giftId[0].id
  //                                     );

  //                                   this.theWheel.animation.stopAngle = stopAt;
  //                                   // if (
  //                                   //   this.global_non_redemmed_similac.length >
  //                                   //   0
  //                                   // ) {
  //                                     this.updateTransactions(transcationNumber);
  //                                     this.startSpin();
  //                                   //}
  //                                 }, err => {
  //                                   this.spinner.hide();
  //                                   this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
  //                                 });
  //                             },
  //                             (err) => {
  //                               this.spinner.hide();
  //                               this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
  //                             }
  //                           );
  //                       },
  //                       (err) => {
  //                         this.spinner.hide();
  //                         this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
  //                       }
  //                     );
  //                 } else if (
  //                   res['status'].code == 1001 ||
  //                   res['status'].code == 400 ||
  //                   res['status'].code == 600 ||
  //                   res['status'].code == 700 ||
  //                   res['status'].code == 701 ||
  //                   res['status'].message ==
  //                   'Something Went Wrong. Please try again later.'
  //                 ) {
  //                   this.spinner.hide();
  //                   this.apiService.showToaster(
  //                     'Đã xảy ra lỗi. Vui lòng thử lại sau'
  //                   );
  //                 } else if (
  //                   res['status'].code == 1001 ||
  //                   res['status'].message ==
  //                   'You have reached the gameplay limit for the month, please try again next month.'
  //                 ) {
  //                   this.spinner.hide();
  //                   this.apiService.showToaster(
  //                     'Bạn đã đạt đến giới hạn trò chơi trong tháng, vui lòng thử lại vào tháng sau.'
  //                   );
  //                 } else if (
  //                   res['status'].code == 401 ||
  //                   res['status'].code == 801 ||
  //                   res['status'].message == 'Please Re-login and try again.'
  //                 ) {
  //                   this.spinner.hide();
  //                   this.apiService.showToaster(
  //                     'Vui lòng đăng nhập lại và thử lại.'
  //                   );
  //                 } else if (
  //                   res['status'].code == 601 ||
  //                   res['status'].message ==
  //                   'There was some problem while playing the game. Please try after some time.'
  //                 ) {
  //                   this.spinner.hide();
  //                   this.apiService.showToaster(
  //                     'Đã xảy ra lỗi trong lúc quay, vui lòng thử lại sau.'
  //                   );
  //                 } else if (
  //                   res['status'].code == 802 ||
  //                   res['status'].code == 803 ||
  //                   res['status'].code == 804 ||
  //                   res['status'].message ==
  //                   'You are not eligible for the game. Please contact support for more details.'
  //                 ) {
  //                   this.spinner.hide();
  //                   this.apiService.showToaster(
  //                     'Bạn không đủ điều kiện để tham gia. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
  //                   );
  //                 } else if (
  //                   res['status'].code == 900 ||
  //                   res['status'].message ==
  //                   'Game has not been started yet. Please contact support for more details.'
  //                 ) {
  //                   this.spinner.hide();
  //                   this.apiService.showToaster(
  //                     'Vòng quay chưa sẵn sàng. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
  //                   );
  //                 } else if (
  //                   res['status'].code == 1000 || res['status'].code == 5503 ||
  //                   res['status'].message ==
  //                   'Game is over for this month. Please contact support for more details.'
  //                 ) {
  //                   this.spinner.hide();
  //                   this.apiService.showToaster('Trò chơi đã kết thúc trong tháng này. Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm chi tiết.')
  //                 } else if (res['status'].code == 5402 || res['status'].message == 'Game Items exhausted, Game over.') {
  //                   this.spinner.hide();
  //                   this.apiService.showToaster('Vật phẩm trong trò chơi đã hết, trò chơi kết thúc.')
  //                 } else if (res['status'].code == 5500 || res['status'].message == 'Exception while fulfilling Game Play request') {
  //                   this.spinner.hide();
  //                   this.apiService.showToaster('Ngoại lệ trong khi đáp ứng yêu cầu Chơi trò chơi')
  //                 }
  //               },
  //               (err) => {
  //                 this.spinner.hide();
  //                 this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
  //                 console.log(err, 'coupon get err')
  //                 //this.apiService.showToaster(err);
  //                 console.log(err, 'login error');
  //               }
  //             );
  //         } else if (
  //           res['status'].code == 1001 ||
  //           res['status'].code == 400 ||
  //           res['status'].code == 600 ||
  //           res['status'].code == 700 ||
  //           res['status'].code == 701 ||
  //           res['status'].message ==
  //           'Something Went Wrong. Please try again later.'
  //         ) {
  //           this.spinner.hide();
  //           this.apiService.showToaster('Đã xảy ra lỗi. Vui lòng thử lại sau');
  //         } else if (
  //           res['status'].code == 1001 ||
  //           res['status'].message ==
  //           'You have reached the gameplay limit for the month, please try again next month.'
  //         ) {
  //           this.spinner.hide();
  //           this.apiService.showToaster(
  //             'Bạn đã đạt đến giới hạn trò chơi trong tháng, vui lòng thử lại vào tháng sau.'
  //           );
  //         } else if (
  //           res['status'].code == 401 ||
  //           res['status'].code == 801 ||
  //           res['status'].message == 'Please Re-login and try again.'
  //         ) {
  //           this.spinner.hide();
  //           this.apiService.showToaster('Vui lòng đăng nhập lại và thử lại.');
  //         } else if (
  //           res['status'].code == 601 ||
  //           res['status'].message ==
  //           'There was some problem while playing the game. Please try after some time.'
  //         ) {
  //           this.spinner.hide();
  //           this.apiService.showToaster(
  //             'Đã xảy ra lỗi trong lúc quay, vui lòng thử lại sau.'
  //           );
  //         } else if (
  //           res['status'].code == 802 ||
  //           res['status'].code == 803 ||
  //           res['status'].code == 804 ||
  //           res['status'].message ==
  //           'You are not eligible for the game. Please contact support for more details.'
  //         ) {
  //           this.spinner.hide();
  //           this.apiService.showToaster(
  //             'Bạn không đủ điều kiện để tham gia. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
  //           );
  //         } else if (
  //           res['status'].code == 900 ||
  //           res['status'].message ==
  //           'Game has not been started yet. Please contact support for more details.'
  //         ) {
  //           this.spinner.hide();
  //           this.apiService.showToaster(
  //             'Vòng quay chưa sẵn sàng. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
  //           );
  //         } else if (
  //           res['status'].code == 1000 ||
  //           res['status'].message ==
  //           'Game is over for this month. Please contact support for more details.'
  //         ) {
  //           this.spinner.hide();
  //           this.apiService.showToaster('Trò chơi đã kết thúc trong tháng này. Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm chi tiết.')
  //         } else if (res['status'].code == 5402 || res['status'].message == 'Game Items exhausted, Game over.') {
  //           this.spinner.hide();
  //           this.apiService.showToaster('Vật phẩm trong trò chơi đã hết, trò chơi kết thúc.')
  //         } else if (res['status'].code == 5500 || res['status'].message == 'Exception while fulfilling Game Play request') {
  //           this.spinner.hide();
  //           this.apiService.showToaster('Ngoại lệ trong khi đáp ứng yêu cầu Chơi trò chơi')
  //         }
  //       },
  //       (err) => {
  //         this.spinner.hide();
  //         this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
  //         // this.apiService.showToaster(err);
  //         console.log(err, 'login error');
  //       }
  //     );
  //   }
  // }

  openRewardPopup() {
    this.rewardModal.show();
  }
  rewardModalHide() {
    this.rewardModal.hide();
    // this.updateTransactions();
    this.router.navigate(['/user-profile']);
    this.resetWheel();
  }

  openMilkCodeModal() {
    this.milkCodeModal.show();
  }
  milkCodeHide() {
    this.milkCodeModal.hide();
    this.router.navigate(['/user-profile']);
    this.resetWheel();
  }

  openbetterLuckModal() {
    this.betterLuckNextTime.show();
  }
  betterLuckModalHide() {
    this.betterLuckNextTime.hide();
    // this.updateTransactions();
    this.router.navigate(['/user-profile']);
    this.resetWheel();
  }
  getSimilacSpinCount(allTransactions) {

    let transList = allTransactions;
    transList.forEach((element) => {
      let data = element?.custom_fields?.field.forEach((cust) => {
        if (cust.name == 'itemcode') {
          element.itemcode = cust.value;
        }
        if (cust.name == 'scanned_date') {
          element.scannedDate = cust.value;
        }
        if (cust.name == 'gift_id') {
          element.giftId = cust.value;
        }
        if (cust.name == 'gift_delivery_date') {
          element.giftdeliveryDate = cust.value;
        }
        if (cust.name == 'is_milk') {
          element.isMilk = cust.value;
        }
        if (cust.name == 'expiration_date') {
          element.expiryDate = cust.value;
          this.scannedDate < cust.value
            ? (element.isNotExpired = true)
            : (element.isNotExpired = false);
        }
        if (cust.name == 'is_grow' && cust.value == 'false') {

          element.isSimilac = 'true';

        }
        if (cust.name == 'is_eligible_for_game') {
          element.eligibleForGame = cust.value;
        }
        if (cust.name == 'is_redeemed') {
          element.isRedemed = cust.value;
        }
      });
    });

    console.log(transList, 'this.Transobj');


    //  New logic for restrict to max 5 per month
    let filteredtranslist = transList.filter((data) => {
      return (
        data?.isSimilac == 'true' &&

        data?.isNotExpired == true &&

        data?.isRedemed == 'false' &&

        data?.isMilk == 'false' &&

        data?.eligibleForGame == 'true'
      );
    });

    let availabelTransactionList = transList.filter((data) => {
      return (
        data?.isSimilac == 'true' &&

        data?.giftId == 'null' &&

        data?.isRedemed == 'false' &&

        data?.isNotExpired == true &&

        data?.isMilk == 'false' &&
        data?.eligibleForGame == 'true'
      );
    });

    let startOfMonth = moment().clone().startOf('month').add(45, 'days').format('YYYY-MM-DD');
    let endOfMonth = moment().clone().endOf('month').add(46, 'days').format('YYYY-MM-DD');

    let consumedTranslist = transList.filter((data) => {
      return (
        //console.log(data?.isGrow, data?.isRedemed, data?.isNotExpired, data?.isMilk, data?.eligibleForGame, data?.scannedDate >= startOfMonth && data?.scannedDate <= endOfMonth) 
       
       data?.isSimilac == 'true' &&

       data?.isRedemed == 'true' &&

       data?.isNotExpired == true &&

       data?.isMilk == 'false' &&

       data?.eligibleForGame == 'true' &&

       data?.giftdeliveryDate >= startOfMonth && data?.giftdeliveryDate <= endOfMonth
      
      
      );
    });

    console.log(consumedTranslist, 'consumedTranslist')


    let custom_fields_details = [];

    filteredtranslist.forEach((transac) => {

      custom_fields_details.push(transac.custom_fields.field);

    });
    custom_fields_details.forEach(element => {
      let data = element?.forEach(cust =>{ 
         if(cust.name == 'scanned_date'){
           element.scannedDate = cust.value
         }
         if(cust.name == 'gift_id'){
           element.giftId = cust.value
         }
         if(cust.name == 'gift_delivery_date'){
           element.giftdeliveryDate = cust.value
         }
         if(cust.name == 'is_milk'){
           element.isMilk = cust.value
         }
         if(cust.name == 'expiration_date'){
           element.expiryDate = cust.value
           this.scannedDate < cust.value ? element.isNotExpired = true : element.isNotExpired = false;
         }
         if(cust.name == 'is_grow'){
           element.isGrow = cust.value 
         }
         if(cust.name == 'is_ensure'){
          element.isEnsure = cust.value 
        }
        if(cust.name == 'is_glucerna'){
          element.isGlucerna = cust.value 
        }
        if(cust.name == 'is_pediasure'){
          element.isPediasure = cust.value 
        }
         if(cust.name == 'is_eligible_for_game'){
           element.eligibleForGame = cust.value 
         }
         if(cust.name == 'is_redeemed'){
           element.isRedemed = cust.value
         }
         if(cust.name == 'itemcode'){
          element.itemcode = cust.value
        }
        if(cust.name == 'first_time_scan'){
          element.first_time_scan = cust.value
        }
       });
 
     });

    console.log(

      custom_fields_details,

      'custom fields of the filtered transactions'

    );

    // New Logic restrict to max 5 per month
  
    let maxNumber = 5;
    let gamesPlayed = consumedTranslist?.length;

    console.log(maxNumber, 'maxNumber')
    console.log(gamesPlayed, 'gamesPlayed')

    let finalChancesofMonth = maxNumber - gamesPlayed;

    console.log(finalChancesofMonth, 'finalChancesofMonth')
    let finalObjects = [...availabelTransactionList, ...consumedTranslist]

    finalObjects = finalObjects.filter((data) => {
      return (
        data?.isRedemed == 'false' 
      );
    });
    finalObjects.sort(function compare(a, b) {
      return +new Date(a.expiryDate) - +new Date(b.expiryDate);
    });   

   finalObjects = finalObjects.splice(0,finalChancesofMonth)

   this.global_non_redemmed_similac = finalObjects;

  sessionStorage.setItem('similac_chances',JSON.stringify(finalObjects.length));
}

  getPreviousTranscation() {
    let mobile_no = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken');
    // let startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
    // let endOfMonth   = moment().clone().endOf('month').add(1, 'days').format('YYYY-MM-DD');

    let curr_date = new Date();
    let start_date = moment(curr_date).subtract(31, 'days');
    let startOfMonth = moment(start_date).format('YYYY-MM-DD');

    let addtoCurrDate = moment(curr_date).add(1, 'days');
    let endOfMonth = moment()
      .clone()
      .endOf('month')
      .add(1, 'day')
      .format('YYYY-MM-DD');

    this.spinner.show();
    let gettransresp
    this.apiService
      .getTransactionfull(mobile_no, getAuthKey)
      .pipe(first())
      .subscribe((res) => {
        gettransresp = res;
        let myuuid = uuidv4();
        let loggerFormData = {
          "unique_id": myuuid,
          "message": JSON.stringify(res)
        }
        this.spinner.hide();
        let custom_fields: any;
        let value: any;
        let result = res['customer']['transactions'];
        let transcation = result['transaction'];

        if (transcation) {
          custom_fields = transcation.map((val) => {
            value = val.custom_fields['field'];
            value.push({ name: 'transaction_id', value: val.number });
            return val.custom_fields['field'];
          });
        }
        if (custom_fields) {
          // this.getTransactionCount(custom_fields);
          this.getSimilacSpinCount(transcation)
        }
      }, err => {
        this.spinner.hide();
        this.bsService.notifyError(err);
      });
  }

  // global_non_redemmed_grow: any = [];
  // global_non_redemmed_ensure: any = [];
  // global_non_redemmed_glucerna: any = [];
  // getTransactionCount(custom_fields1) {
  //   this.global_non_redemmed_grow = [];
  //   this.global_non_redemmed_ensure = [];
  //   this.global_non_redemmed_glucerna = [];
  //   let non_redeemed_grow = [];
  //   let non_redeemed_similac = [];
  //   let nonmilk_similac_array = [];
  //   let nonmilk_grow_array = [];
  //   let all_nonmilk_array = []

  //   let non_redeemed_ensure = [];
  //   let non_redeemed_glucerna = [];
  //   let nonmilk_ensure_array = [];
  //   let nonmilk_glucerna_array = [];

  //   custom_fields1.filter(custom_fields => {

  //     console.log(custom_fields, 'filter custom');
  //     if (custom_fields?.name == 'is_grow') {
  //       let a = custom_fields.findIndex(val => val?.name == 'is_grow');
  //       let b = custom_fields.findIndex(val => val?.name == 'is_milk');
  //       if (a > b) {
  //         let temp = custom_fields[a];
  //         custom_fields[a] = custom_fields[b];
  //         custom_fields[b] = temp;
  //       }
  //     }
  //     if (custom_fields?.name == 'is_ensure') {
  //       let a = custom_fields.findIndex(val => val?.name == 'is_ensure');
  //       let b = custom_fields.findIndex(val => val?.name == 'is_milk');
  //       if (a > b) {
  //         let temp = custom_fields[a];
  //         custom_fields[a] = custom_fields[b];
  //         custom_fields[b] = temp;
  //       }
  //     }
  //     if (custom_fields?.name == 'is_glucerna') {
  //       let a = custom_fields.findIndex(val => val?.name == 'is_glucerna');
  //       let b = custom_fields.findIndex(val => val?.name == 'is_milk');
  //       if (a > b) {
  //         let temp = custom_fields[a];
  //         custom_fields[a] = custom_fields[b];
  //         custom_fields[b] = temp;
  //       }
  //     }



  //     let c = custom_fields.findIndex(val => val?.name == 'is_redeemed');
  //     let d = custom_fields.findIndex(val => val?.name == 'is_milk');
  //     if (c > d) {
  //       let temp = custom_fields[c];
  //       custom_fields[c] = custom_fields[d];
  //       custom_fields[d] = temp;
  //     }

  //     console.log(custom_fields, 'custom_fields-----');
  //     custom_fields.filter(val => {
  //       console.log(val, 'val----')
  //       if (val?.name == 'is_grow') {
  //         //val.value == "true" ? this.custom_isGrow = true : this.custom_isGrow = false;
  //         if (val.value == "true") {
  //           this.custom_isGrow = true;
  //           this.custom_isEnsure = false;
  //           this.custom_isGlucerna = false;
  //         } else if (val.value == "false") {
  //           this.custom_isGrow = false;
  //           this.custom_isEnsure = false;
  //           this.custom_isGlucerna = false;
  //         }
  //         console.log(val.value, 'is_grow_value')
  //         console.log(this.custom_isGrow, 'custom_isGrow value')
  //         console.log(this.custom_isGrow, 'custom_isGrow value')
  //       }

  //       if (val.name == 'is_ensure') {
  //         // val.value == "true" ? this.custom_isEnsure = true : this.custom_isGrow = 'null';
  //         if (val.value == "true") {
  //           this.custom_isGrow = 'null';
  //           this.custom_isEnsure = true;
  //           this.custom_isGlucerna = false;
  //         } else {
  //           this.custom_isGrow = 'null';
  //           this.custom_isEnsure = false;
  //           this.custom_isGlucerna = false;
  //         }
  //         console.log(val.value, 'is_ensure_value')
  //         console.log(this.custom_isEnsure, 'custom_isEnsure value')
  //         console.log(this.custom_isGrow, 'custom_isGrow value')
  //       }

  //       if (val.name == 'is_glucerna') {

  //         //val.value == "true" ? this.custom_isGlucerna = true : this.custom_isGrow = false;
  //         if (val.value == "true") {
  //           this.custom_isGrow = 'null';
  //           this.custom_isEnsure = false;
  //           this.custom_isGlucerna = true;
  //         } else {
  //           this.custom_isGrow = 'null';
  //           this.custom_isEnsure = false;
  //           this.custom_isGlucerna = false;
  //         }
  //         console.log(val.value, 'is_glucerna_value')
  //         console.log(this.custom_isGlucerna, 'custom_isGlucerna value')
  //         console.log(this.custom_isGrow, 'custom_isGrow value')
  //       }


  //       if (val.name == "expiration_date") {
  //         this.scannedDate < val.value ? this.is_not_expired = true : this.is_not_expired = false;
  //       }

  //       if (val.name == "is_eligible_for_game") {
  //         val.value == "true" ? this.game_is_eligible = true : this.game_is_eligible = false;
  //       }

  //       if (val.name == 'is_redeemed') {
  //         val.value == "true" ? this.redemmed = true : this.redemmed = false;
  //       }

  //       if (val.name == 'is_milk' && val.value == "false") {
  //         all_nonmilk_array.push(custom_fields);
  //         if (this.custom_isEnsure) {
  //           console.log(this.custom_isEnsure, 'this.custom_isEnsure')
  //           nonmilk_ensure_array.push(custom_fields);
  //           console.log(nonmilk_ensure_array, 'nonmilk_ensure_array')
  //         }
  //         else if (this.custom_isGlucerna) {
  //           console.log(this.custom_isGlucerna, 'this.custom_isGlucerna')
  //           nonmilk_glucerna_array.push(custom_fields);
  //           console.log(nonmilk_glucerna_array, 'nonmilk_glucerna_array')
  //         }
  //         else if ((this.custom_isGrow == true && this.custom_isGrow != 'null')) {
  //           console.log(this.custom_isGrow, 'this.custom_isGrow')
  //           nonmilk_grow_array.push(custom_fields);
  //           console.log(nonmilk_grow_array, 'nonmilk_grow_array')
  //         }
  //         else if ((this.custom_isGrow == false && this.custom_isGrow != 'null')) {
  //           console.log(this.custom_isGrow, 'this.custom_isGrow')
  //           nonmilk_similac_array.push(custom_fields);
  //           console.log(nonmilk_similac_array, 'nonmilk_similac_array')
  //         }
  //         if (this.custom_isEnsure && this.is_not_expired && this.redemmed == false && this.game_is_eligible) {
  //           console.log(this.custom_isEnsure, 'this.custom_isEnsure')
  //           non_redeemed_ensure.push(custom_fields)
  //           console.log(non_redeemed_ensure, 'non_redeemed_ensure')
  //         }
  //         if (this.custom_isGlucerna && this.is_not_expired && this.redemmed == false && this.game_is_eligible) {
  //           console.log(this.custom_isGlucerna, 'this.custom_isGlucerna')
  //           non_redeemed_glucerna.push(custom_fields)
  //           console.log(non_redeemed_glucerna, 'non_redeemed_glucerna')
  //         }
  //         if ((this.custom_isGrow == true && this.custom_isGrow != 'null') && this.is_not_expired && this.redemmed == false && this.game_is_eligible) {
  //           console.log(this.custom_isGrow, 'this.custom_isGrow')
  //           non_redeemed_grow.push(custom_fields)
  //           console.log(non_redeemed_grow, 'non_redeemed_grow')
  //         }
  //         if ((this.custom_isGrow == false && this.custom_isGrow != 'null') && this.is_not_expired && this.redemmed == false && this.game_is_eligible) {
  //           console.log(this.custom_isGrow, 'this.custom_isGrow')
  //           non_redeemed_similac.push(custom_fields);
  //           console.log(non_redeemed_similac, 'non_redeemed_similac')
  //         }
  //       }

  //     })


  //     let array_copy = [...non_redeemed_similac];

  //     var sortedArray = array_copy.sort(function (a, b) {
  //       if (
  //         a[5]?.name == 'expiration_date' &&
  //         b[5]?.name == 'expiration_date'
  //       ) {
  //         return +new Date(a[5].value) - +new Date(b[5].value);
  //       } else if (
  //         a[6]?.name == 'expiration_date' &&
  //         b[6]?.name == 'expiration_date'
  //       ) {
  //         return +new Date(a[6].value) - +new Date(b[6].value);
  //       } else if (
  //         a[7]?.name == 'expiration_date' &&
  //         b[7]?.name == 'expiration_date'
  //       ) {
  //         return +new Date(a[7].value) - +new Date(b[7].value);
  //       }
  //     });

  //     this.global_non_redemmed_similac = sortedArray;

  //     console.log(
  //       this.global_non_redemmed_similac,
  //       'this.global_non_redemmed_similac ---'
  //     );
  //     console.log(nonmilk_grow_array, 'nonmilk_grow_array');
  //     console.log(nonmilk_similac_array, 'nonmilk_similac_array');

  //     let all_nonmilk_count = all_nonmilk_array.length; //given final count of non-milk(both grow and similac)
  //     let grow_nonmilk_count = nonmilk_grow_array.length; // gives final count non-milk grow
  //     let similac_nonmilk_count = nonmilk_similac_array.length; // gives final count non-milk similac

  //     // sessionStorage.setItem('similacMilCount', JSON.stringify(similac_nonmilk_count));
  //     // sessionStorage.setItem('growMilkCount', JSON.stringify(grow_nonmilk_count));

  //     let remain_nonmilk_grow_count = 5 - grow_nonmilk_count;
  //     let remain_nonmilk_similac_count = 5 - similac_nonmilk_count;

  //     // sessionStorage.setItem('grow_nonmilk_remaining_count', remain_nonmilk_grow_count.toString());
  //     // sessionStorage.setItem('similac_nonmilk_remaining_count', remain_nonmilk_similac_count.toString());

  //     grow_nonmilk_count <= 5
  //       ? (this.eligible_nonmilk_grow = true)
  //       : (this.eligible_nonmilk_grow = false);
  //     similac_nonmilk_count <= 5
  //       ? (this.eligible_nonmilk_similac = true)
  //       : (this.eligible_nonmilk_similac = false);

  //     // this.remaining_grow_chances = remain_nonmilk_grow_count
  //     // this.remaining_similac_chances = remain_nonmilk_similac_count

  //     // this.similac_array_length = similac_nonmilk_count
  //     // this.grow_array_length = grow_nonmilk_count

  //     // if(this.similac_array_length == 0 || this.similac_array_length == null){
  //     //   this.remaining_similac_chances = 5;
  //     // }
  //     // if(this.grow_array_length == 0 || this.similac_array_length == null){
  //     //   this.remaining_grow_chances = 5;
  //     // }

  //     // this.remaining_grow_chances  = this.remaining_grow_chances < 0 ? this.remaining_grow_chances = 0 : 0 + this.remaining_grow_chances
  //     // this.remaining_similac_chances  = this.remaining_similac_chances < 0 ? this.remaining_similac_chances = 0 : 0 + this.remaining_similac_chances

  //     // console.log(this.remaining_grow_chances, 'remain grow -- in similac wheel');
  //     // console.log(this.remaining_similac_chances, 'remaining simili ---in similac wheel')
  //   });
  // }



  // updateTransactions(transcationNumber) {
  //   // if (this.global_non_redemmed_similac.length > 0) {
  //   //   let val = this.global_non_redemmed_similac[0].length;
  //   //   let id = this.global_non_redemmed_similac[0][val - 1].value;
  //     let getMobile = sessionStorage.getItem('mobileNo');
  //     let getAuthKey = sessionStorage.getItem('authToken');
  //     let productName = sessionStorage.getItem('productName');
  //     let formData = {
  //       transaction_number: transcationNumber,
  //       //transaction_number: this.timestamp,
  //       mobile: env.COUNTRY_CODE + getMobile,
  //       custom_fields: [
  //         {
  //           name: 'is_redeemed',
  //           value: true,
  //         },
  //         {
  //           name: 'gift_id',
  //           value: this.user_gift != 'P-00000' ? this.user_gift : 'P-00000',
  //         },
  //         {
  //           name: 'gift_delivery_date',
  //           value: this.gift_delivery_date,
  //         },
  //       ],
  //       extended_fields: [
  //         {
  //           name: "external_coupon_title",
  //           value: 'True'
  //         }
  //       ]
  //     };
  //     this.spinner.show();
  //     console.log(formData, 'upadte form data');
  //     let myuuid = uuidv4();
  //     let loggerFormData = {
  //       "unique_id": myuuid,
  //       "message": JSON.stringify(formData)
  //     }
  //     let updatetransresp;
  //     this.apiService
  //       .updateTrans(formData, getAuthKey)
  //       .pipe(first()) //make this update transaction
  //       .subscribe((res) => {
  //         this.spinner.hide();
  //         updatetransresp = res;
  //         let myuuid = uuidv4();
  //         let loggerFormData = {
  //           "unique_id": myuuid,
  //           "message": JSON.stringify(res)
  //         }
  //         this.getPreviousTranscation();
  //         // this.spinner.hide();
  //         // this.router.navigate(['/user-profile'])
  //       }, err => {
  //         this.spinner.hide();
  //       });
  //   // } else {
  //   //   console.log('no trasaction available----');
  //   // }
  // }

  //---------------------scanner----------------------------------

  onClickScanner() {
    this.showScanner = true;
    this.showProfile = false;
    this.successQrCodeModal.hide();

    if (this.showScanner) {
      this.startVideo();
    }
  }
  toggleVideoMedia() {
    if (this.videoStart) {
      this.stopVideo();
    } else {
      this.startVideo();
    }
  }

  startVideo() {
    this.medias.video = { facingMode: 'environment', width: 100, height: 150 };
    navigator.mediaDevices
      .getUserMedia(this.medias)
      .then((localStream: MediaStream) => {
        this.videoElm.nativeElement.srcObject = localStream;
        this.videoStart = true;
        this.checkImage();
      })
      .catch((error) => {
        console.error(error);
        this.videoStart = false;
      });
  }

  stopVideo() {
    this.medias.video = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].enabled = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].stop();
    this.videoStart = false;
  }

  checkImage() {
    const WIDTH = this.videoElm.nativeElement.clientWidth;
    const HEIGHT = this.videoElm.nativeElement.clientHeight;
    this.canvasElm.nativeElement.width = WIDTH;
    this.canvasElm.nativeElement.height = HEIGHT;

    const ctx = this.canvasElm.nativeElement.getContext(
      '2d'
    ) as CanvasRenderingContext2D;

    ctx.drawImage(this.videoElm.nativeElement, 0, 0, WIDTH, HEIGHT);
    const imageData = ctx.getImageData(0, 0, WIDTH, HEIGHT);
    const code = jsQR(imageData.data, imageData.width, imageData.height, {
      inversionAttempts: 'dontInvert',
    });
    if (code) {
      let scannedCode = code.data;
      let getAuthKey = sessionStorage.getItem('authToken');
      window.location.href = scannedCode;
      //   this.apiService.getTraceability(scannedCode).subscribe((res: any) => {
      //     sessionStorage.setItem('productdata', JSON.stringify(res.result.data));
      //     this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
      //     console.log(this.scancodeData, 'productdata')
      //     if(res.result.code == '0'){
      //         if(res.result.data.uaidInfo.type == 1 || res.result.data.uaidInfo.type == 7){
      //         this.router.navigate(['/home']);
      //         }else if(res.result.data.uaidInfo.type == 2){
      //         if(getAuthKey){
      //             console.log('available Auth kery', getAuthKey)
      //       this.apiService.getPosition().then(pos => {
      //         let lat = pos.lat
      //         let lang = pos.lng
      //         let latitude = lat.toString()
      //         let langitude = lang.toString()
      //         let getMobile = sessionStorage.getItem('mobileNo')
      //         let getAuthKey = sessionStorage.getItem('authToken')
      //         let productName = sessionStorage.getItem('productName');
      //         let formData = {
      //             "scan_code": scannedCode,
      //             "mobile": getMobile,
      //             "cap_device_id": env.DEVICE_ID,
      //             "custom_fields": [
      //               {
      //                 "name": "TransLat",
      //                 "value": latitude
      //               },
      //               {
      //                 "name": "TransLong",
      //                 "value": langitude
      //              },
      //              {
      //                 "name": "productName",
      //                 "value": productName
      //              },
      //              {
      //               "name":"categorycode",
      //               "value":this.categoryCode ? this.categoryCode : ''
      //             },
      //             {
      //               "name":"is_milk",
      //               "value": this.is_milk

      //             },
      //             {
      //               "name": "is_grow",
      //               "value": this.is_grow

      //             },
      //             {
      //               "name": "itemcode",
      //               "value": this.subCatCode
      //             },
      //             {
      //               "name": "scanned_date",
      //               "value": this.scannedDate
      //             },
      //             {
      //               "name": "expiration_date",
      //               "value": this.expiryDate
      //             },
      //             {
      //               "name": "is_eligible_for_game",
      //               "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
      //             },
      //             {
      //               "name": "is_redeemed",
      //               "value": false
      //             },
      //             {
      //               "name": "gift_id",
      //               "value": 'null'
      //             },
      //             {
      //               "name": "gift_delivery_date",
      //               "value": 'null'
      //             }

      //           ]
      //         }
      //         this.spinner.show();
      //         this.apiService.addTransaction(formData, getAuthKey).pipe(first())
      //         .subscribe((res:any) => {
      //           console.log("res", res?.result?.data?.product?.txt);
      //           let milkcodeFind = res?.result?.data?.product?.txt;
      //           if(milkcodeFind){
      //             milkcodeFind.forEach((item: any) => {
      //               if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
      //                 let parsedPrimaryValue = this.parseFunction(item.value);
      //                 this.FinalMilkcode = parsedPrimaryValue.value;
      //                 console.log(this.FinalMilkcode, 'FinalMilkcode')
      //               }
      //             });
      //           }

      //           this.spinner.hide();
      //           this.getPreviousTranscation;
      //           this.showScanner = false;
      //           this.toggleVideoMedia();

      //           if(this.FinalMilkcode == '1'){
      //             this.openMilkCodeModal();
      //           }

      //           if(this.FinalMilkcode == '0'){
      //           let SimilacCount = JSON.parse(sessionStorage.getItem('similacMilCount'))
      //           let GrowCount = JSON.parse(sessionStorage.getItem('growMilkCount'))
      //           console.log(SimilacCount, 'GrowCount')
      //           console.log(GrowCount, 'GrowCount')

      //           if(this.is_grow){
      //             if(this.remaining_grow_chances < 5){
      //               this.successQrCodeModal.show();
      //             }
      //             else{
      //               this.router.navigate(['/lucky-draw-quota-exceed']);
      //             }
      //           }
      //           else{
      //             if(this.remaining_similac_chances < 5){
      //               console.log(this.remaining_similac_chances, 'this.remaining_similac_chances')
      //               this.successQrCodeModal.show();
      //             }
      //             else{
      //               console.log(this.remaining_similac_chances, 'this.remaining_similac_chances2')
      //               this.router.navigate(['/lucky-draw-quota-exceed']);
      //             }
      //           }
      //         }
      //           // this.getCustomer();
      //         }, err => {
      //           this.showScanner = false;
      //           this.spinner.hide();
      //           // this.apiService.showToaster(err);
      //           if(err?.error?.message == "Given code already verified"){
      //             this.openinvalidQrCodeModal()
      //           }else if('Đã xảy ra lỗi. Vui lòng thử lại sau' == "Invalid scanned code" || err == "Wrong scanned code"){
      //             // this.qrCode1Modal.show();
      //           }

      //           this.toggleVideoMedia();
      //         })
      //       })
      //         }else{
      //             this.router.navigate(['/sign-up']);
      //         }

      //         }
      //     }
      // },err=>{
      //         console.log(err, 'err========')
      // })
    } else {
      setTimeout(() => {
        this.checkImage();
      }, 100);
    }
  }

  openinvalidQrCodeModal() {
    this.invalidQrCodeModal.show();
  }
  invalidQrCodeHide() {
    this.router.navigate(['/user-profile']);
    this.invalidQrCodeModal.hide();
  }
  linktoUserProfile() {
    this.router.navigate(['/user-profile']);
  }
  closeScanner() {
    this.showScanner = false;
    this.showProfile = true;
  }

  closeSuccessModal() {
    this.successQrCodeModal.hide();
    this.router.navigate(['/user-profile']);
  }
  growOrSimilac() {
    if (this.is_grow && this.eligible_nonmilk_grow) {
      this.modalService.hide();
      this.router.navigate(['/grow-spinwheel']);
    }
    if (this.is_grow && this.is_eligilbe_grow == false) {
      this.router.navigate(['/lucky-draw-quota-exceed']);
    }
    if (this.is_grow == false && this.eligible_nonmilk_similac) {
      this.modalService.hide();
      this.router.navigate(['/similac-spinwheel']);
    }
  }

  opensimilacTerms() {
    this.termsSimilac.show();
  }
  termsSimilacHide() {
    this.termsSimilac.hide();
  }
  openSimilacTermsandConditions() {
    this.opensimilacTerms();
  }
  ensuretermsContent: any;
  glucernatermsContent: any;
  pediasureltermsContent: any;
  similacTerms() {
    this.apiService.similacTermsandConditions().subscribe((res: any) => {
      res.data.forEach(element => {
        if (element.contentkey == 'Terms_and_conditions_Similac') {
          this.termsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_Grow') {
          this.growtermsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_Gold') {
          this.ensuretermsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_Glucerna') {
          this.glucernatermsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_PediaSure') {
          this.pediasureltermsContent = element.content;
        }

      })
    })
  }




  initialUpdateTransactions() {
    this.spinner.show();
    let getMobile = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken');
    this.apiService
      .getTransactionfull(getMobile, getAuthKey).pipe(first()).subscribe((res: any) => {
        this.spinner.hide();

        let transList = res?.customer?.transactions?.transaction;
        transList.forEach(element => {
          let data = element?.custom_fields?.field.forEach(cust => {
            if (cust.name == 'scanned_date') {
              element.scannedDate = cust.value
            }
            if (cust.name == 'gift_id') {
              element.giftId = cust.value
            }
            if (cust.name == 'gift_delivery_date') {
              element.giftdeliveryDate = cust.value
            }
            if (cust.name == 'is_milk') {
              element.isMilk = cust.value
            }
            if (cust.name == 'itemcode') {
              element.itemcode = cust.value
            }
            if (cust.name == 'expiration_date') {
              element.expiryDate = cust.value
              this.scannedDate < cust.value ? element.isNotExpired = true : element.isNotExpired = false;
            }
            if (cust.name == 'is_grow') {
              element.isGrow = cust.value
            }
            if (cust.name == 'is_eligible_for_game') {
              element.eligibleForGame = cust.value
            }
            if (cust.name == 'is_redeemed') {
              element.isRedemed = cust.value
            }
          });

        });
        console.log(transList, 'this.Transobj')
        let filteredtranslist = transList.filter(data => {
          return data?.isGrow == 'false' && data?.isRedemed == 'false' && data?.giftId == 'null' && data?.isNotExpired == true && data?.isMilk == 'false';
        })
        console.log(filteredtranslist, 'filteredtranslist')
        filteredtranslist.sort(function compare(a, b) {
          return +new Date(a.expiryDate) - +new Date(b.expiryDate);
        });
        console.log(filteredtranslist, 'filteredtranslist after sort')
        let transactions = filteredtranslist
        let transactionNumber = transactions[0]?.number;
        let itemCode = transactions[0]?.itemcode;

        if (transactions?.length > 0) {
          this.gameAllocation(transactionNumber, itemCode);
          // let val = transactions.length;
          // let transcationNumber = transactions[0].number;
          // let itemCode = transactions[0].itemcode; 
          // let formData = {
          //   transaction_number: transcationNumber,
          //   //transaction_number: this.timestamp,
          //   mobile: env.COUNTRY_CODE+getMobile,
          //   custom_fields: [
          //     {
          //       name: 'is_redeemed',
          //       value: true,
          //     },
          //     {
          //       name: 'gift_id',
          //       value: 'Pending',
          //     },
          //     {
          //       name: 'gift_delivery_date',
          //       value: 'Pending',
          //     },
          //   ],
          //   extended_fields: [
          //     {
          //       name: "external_coupon_title",
          //       value: 'True'
          //     }
          //   ]
          // };
          // this.spinner.show();
          // this.apiService
          //   .updateTrans(formData, getAuthKey).pipe(first()).subscribe((res:any) => {
          //     if(res?.result?.response?.status?.code == 200){
          //       this.spinner.hide();
          //       console.log(res, 'update transaction resp====');
          //       this.gameAllocation(transcationNumber,itemCode);

          //       //this.getPreviousTranscation();
          //       // this.spinner.hide();
          //       // this.router.navigate(['/user-profile'])
          //     }else{
          //       this.spinner.hide();
          //       this.apiService.showErrorMsg('Something went wrong, Please try again')
          //     }
          //   }, err=>{
          //     this.spinner.hide();
          //   });

        } else {
          console.log('no trasaction available----');
          this.apiService.showErrorMsg('You dont have enough chances to play the game.')
        }
      }, (err) => {
        this.spinDisable = false;
        this.spinner.hide();
        this.apiService.showErrorMsg('Something went wrong, Please try again')
      });



  }

}
